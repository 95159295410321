.profile-pic {
    display: inline-block;
    border-radius: 50%;
    width: 4em;
    margin: 0.5em;
}

.img-and-text textarea {
    display: inline-block;
    border: 1px solid range;
    border-radius: 0.5em;
    width: 100%;
    color: orange;
    background: transparent;
    outline: none;
    font-size: 2em;
    resize: none;
    /* padding: 0.5em; */
}

.float-right {
    float: right;
}

.twoot-container button {
    background: transparent;
    transition: background-color 1s, color 1s;
    border: 1px solid orange;
    color: orange;
    border-radius: 0.5em;
    margin-left: 1em;
    font-size: 1.2em;
    padding: 0.5em;
}

.twoot-container button:hover {
    background-color: orange;
    color: #333;
}

.twoot-container button:disabled {
    background-color: red;
}

.twoot-wrapper {
    display: grid;
    grid-template-columns: 0.1fr 1fr;
}

@media only screen and (max-width: 487px) {
    .profile-pic {
        width: 2em;
    }
}
