/* GLITCH WORDS EFFECT */
.glitch {
    color: orange;
    font-weight: bold;
    position: relative;
    /* width: 400px; */
    margin: 0 auto;
}

@keyframes noise-anim {
    0% {
        clip-path: inset(89% 0 1% 0);
    }
    5% {
        clip-path: inset(93% 0 1% 0);
    }
    10% {
        clip-path: inset(70% 0 14% 0);
    }
    15% {
        clip-path: inset(76% 0 11% 0);
    }
    20% {
        clip-path: inset(9% 0 20% 0);
    }
    25% {
        clip-path: inset(50% 0 38% 0);
    }
    30% {
        clip-path: inset(56% 0 7% 0);
    }
    35% {
        clip-path: inset(64% 0 15% 0);
    }
    40% {
        clip-path: inset(3% 0 26% 0);
    }
    45% {
        clip-path: inset(91% 0 6% 0);
    }
    50% {
        clip-path: inset(79% 0 22% 0);
    }
    55% {
        clip-path: inset(76% 0 2% 0);
    }
    60% {
        clip-path: inset(75% 0 26% 0);
    }
    65% {
        clip-path: inset(12% 0 65% 0);
    }
    70% {
        clip-path: inset(98% 0 1% 0);
    }
    75% {
        clip-path: inset(90% 0 8% 0);
    }
    80% {
        clip-path: inset(18% 0 33% 0);
    }
    85% {
        clip-path: inset(15% 0 53% 0);
    }
    90% {
        clip-path: inset(10% 0 42% 0);
    }
    95% {
        clip-path: inset(90% 0 3% 0);
    }
    100% {
        clip-path: inset(58% 0 23% 0);
    }
}
.glitch::after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    top: 0;
    color: orange;
    background: rgb(19, 19, 19);
    overflow: hidden;
    animation: noise-anim 2s infinite linear alternate-reverse;
}
@keyframes noise-anim-2 {
    0% {
        clip-path: inset(61% 0 33% 0);
    }
    5% {
        clip-path: inset(53% 0 29% 0);
    }
    10% {
        clip-path: inset(13% 0 20% 0);
    }
    15% {
        clip-path: inset(39% 0 57% 0);
    }
    20% {
        clip-path: inset(45% 0 12% 0);
    }
    25% {
        clip-path: inset(57% 0 12% 0);
    }
    30% {
        clip-path: inset(71% 0 14% 0);
    }
    35% {
        clip-path: inset(30% 0 7% 0);
    }
    40% {
        clip-path: inset(4% 0 52% 0);
    }
    45% {
        clip-path: inset(23% 0 32% 0);
    }
    50% {
        clip-path: inset(58% 0 17% 0);
    }
    55% {
        clip-path: inset(75% 0 14% 0);
    }
    60% {
        clip-path: inset(72% 0 19% 0);
    }
    65% {
        clip-path: inset(28% 0 66% 0);
    }
    70% {
        clip-path: inset(57% 0 10% 0);
    }
    75% {
        clip-path: inset(84% 0 4% 0);
    }
    80% {
        clip-path: inset(55% 0 7% 0);
    }
    85% {
        clip-path: inset(71% 0 19% 0);
    }
    90% {
        clip-path: inset(95% 0 4% 0);
    }
    95% {
        clip-path: inset(51% 0 49% 0);
    }
    100% {
        clip-path: inset(28% 0 70% 0);
    }
}
.glitch::before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 orange;
    top: 0;
    color: orange;
    background: rgb(19, 19, 19);
    overflow: hidden;
    animation: noise-anim-2 15s infinite linear alternate-reverse;
}
