.wrapper {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr;
    max-width: 90%;
    grid-gap: 1em;
    height: 80vh;
    margin: 0 auto;
}

.wrapper .left-nav {
    padding-top: 2em;
}

.wrapper .board {
    opacity: 0.9;
    padding: 1em;
    border-radius: 1em;
    border: 2px solid orange;
    max-width: 700px;
    overflow-y: scroll;
}

.wrapper .waves {
    max-width: 300px;
    padding: 1em;
    border-radius: 1em;
}

@media only screen and (max-width: 768px) {
    .waves {
        display: none;
    }

    .wrapper {
        grid-template-columns: 0.1fr 1fr;
    }
}

@media only screen and (max-width: 478px) {
    .wrapper {
        grid-template-columns: 1fr;
        height: 80vh;
    }

    .left-nav {
        display: none;
    }
}
