.left-nav-btn-container {
    display: flex;
    justify-content: center;
}

.left-nav-btn {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    font-size: 2em;
    transition: background-color 1s;
    border-radius: 1em;
    padding: 0.5em 1em;
    text-align: center;
    padding-right: 1em;
}

.left-nav-btn:hover {
    background-color: #ff7b02;
    color: #fff;
}

@media only screen and (max-width: 768px) {
    .left-nav-btn {
        font-size: 1.1em;
    }
}
